import React, {FC} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {numberWithCommas} from "../utils";

interface IProps {
    items: any;
    currency: string;
    total_payable: number;
    tax_total: number;
    total_amount: number;
}

const InvoiceItems: FC<IProps> = (props: IProps) => {
    const {
        items,
        currency='',
        tax_total,
        total_payable,
        total_amount
    } = props;
    if (items === undefined)
        return null;

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{ backgroundColor: 'black'}}>
                        <TableCell sx={{color: 'white'}}>Classification</TableCell>
                        <TableCell sx={{color: 'white'}}>Description</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Quantity</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Unit Price</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Amount</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Disc</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Tax Rate</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Tax Amount</TableCell>
                        <TableCell sx={{color: 'white'}} align="right">Total (incl. tax)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row: any, index: number) => (
                        <TableRow key={index}>
                            <TableCell>{row?.classification_code || ''}</TableCell>
                            <TableCell>{row?.item_name || ''}</TableCell>
                            <TableCell align="right">{row?.quantity || ''}</TableCell>
                            <TableCell align="right">{currency} {numberWithCommas(row.unit_price || '')}</TableCell>
                            <TableCell align="right">{currency} {numberWithCommas(row.total_without_tax || '')}</TableCell>
                            <TableCell align="right">{numberWithCommas(row.total_discount || '')}</TableCell>
                            <TableCell align="right">{numberWithCommas(row.tax_rate || '')}</TableCell>
                            <TableCell align="right">{numberWithCommas(row.total_tax || '')}</TableCell>
                            <TableCell align="right">{currency} {numberWithCommas(row?.item_total_amount || '')}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={12} align="right" sx={{ color: 'white'}}>e</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={8} align="right">Subtotal</TableCell>
                        <TableCell align="right">{currency} {numberWithCommas(total_amount || 0)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={8} align="right">Tax amount</TableCell>
                        <TableCell align="right">{currency} {numberWithCommas(tax_total || 0)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={8} align="right">
                            <Typography variant="subtitle1">Total payable amount</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="h6" fontWeight="bold">{currency} {numberWithCommas(total_payable || 0)}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InvoiceItems;
