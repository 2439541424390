export const EN_LANG_CONFIG: { [key: string]: any } = {
    'home_screen_header': 'Welcome',
    'home_screen_description': 'Log into your account to use the MMUnity App',
    'home_tin': 'Tax Information Number (TIN)',
    'home_idtype': 'Choose an Identification Type',
    'home_idvalue': 'Identification Number',
    'home_refno': 'MM Reference No',
    'home_submit_button': 'Submit',
    'home_exception': 'OOPS SOMETHING WENT WRONG',
    'home_tin_required': 'Tax Information Number is required',
    'home_idtype_required': 'Please Choose an Identification Type',
    'home_idvalue_required': 'Identification Number is required',
    'home_refno_required': 'MM Reference No is required',
    'home_back_button': 'Back'
}
