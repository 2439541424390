import React, {FC} from 'react';
import {Box, Button, Card, CardContent, Divider} from "@mui/material";
import Header from "./Header";
import InvoiceSection from "./InvoiceSection";
import SupplierBuyer from "./SupplierBuyer";
import InvoiceItems from "./InvoiceItems";
import Footer from "./Footer";
import {getLabelValue} from "../utils";

interface IProps {
    invoice: any;
    isMobile: boolean;
    lang: string;
    goBack: () => void;
}

const Invoice: FC<IProps> = (props:IProps) => {
    const {invoice, isMobile, lang, goBack} = props;
    return (
        <Box
            sx={{
                backgroundColor: isMobile ? 'white' : 'lightgray',
                width: '100vw',
                //height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            my={isMobile ? 8 : 10}
        >
            <Card
                sx={{
                    width: isMobile ? '100%' :'70%',
                    padding: isMobile ? '10px' : '20px',
                    borderRadius: 0
                }}
            >
                <CardContent>
                    <Header details={invoice?.supplier_data} />
                    <Divider sx={{ paddingTop: 1}} />
                    <InvoiceSection
                        details={{
                            invoice_number: invoice?.invoice_number,
                            document_date: invoice?.document_date,
                            document_type: invoice?.document_type,
                        }}
                        isMobile={isMobile}
                    />
                    <Divider sx={{ paddingTop: 2}} />
                    <SupplierBuyer
                        supplier={invoice?.supplier_data}
                        buyer={invoice?.buyer_data}
                        isMobile={isMobile}
                    />
                    <Divider sx={{ paddingTop: 1}} />
                    <InvoiceItems
                        items={invoice?.items}
                        currency={invoice?.currency}
                        total_payable={invoice?.total_payable}
                        tax_total={invoice?.tax_total}
                        total_amount={invoice?.total_amount}
                    />
                    <Footer signature={invoice?.digital_signature} />
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            variant="contained"
                            sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                            onClick={() => goBack()}
                        >
                            {getLabelValue(lang, 'home_back_button')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Invoice;
