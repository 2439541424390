import React from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./screens/Home";
import {theme} from "./theme";
import AppBarComponent from "./components/AppBarComponent";

function App() {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBarComponent />
        <Routes>
          <Route
              path="/check"
              element={<Home />}
          />
          <Route path="*" element={<Navigate to={'/check'} replace />} />
        </Routes>
      </ThemeProvider>
  );
}

export default App;
