export const BM_LANG_CONFIG: { [key: string]: any } = {
    'home_screen_header': 'Welcome',
    'home_screen_description': 'Log into your account to use the MMUnity App',
    'home_tin': 'Nombor Pengenalan Cukai (TIN)',
    'home_idtype': 'Pilih Jenis Pengenalan',
    'home_idvalue': 'No. Pengenalan',
    'home_refno': 'MM Reference No',
    'home_submit_button': 'Hantar',
    'home_exception': 'OOPS ADA YANG SILAP',
    'home_tin_required': 'Nombor Maklumat Cukai diperlukan',
    'home_idtype_required': 'Sila Pilih Jenis Pengenalan',
    'home_idvalue_required': 'Nombor Pengenalan diperlukan',
    'home_refno_required': 'No Rujukan MM diperlukan',
    'home_back_button': 'Belakang'
}
