import React, {FC} from 'react';
import {Box, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

interface IProps {
    details: any;
    isMobile: boolean;
}

const InvoiceSection: FC<IProps> = (props: IProps) => {
    const {details, isMobile}= props;
    if (details === undefined)
        return null;
    const { invoice_number, document_date, document_type,} = details;

    return (
        <Box
            display="flex"
            flexDirection="column"
            mt={4}
        >
            <Typography variant="h6" textAlign={isMobile ? "center" : "left"} sx={{ paddingBottom: 1}}>
                E-INVOICE
            </Typography>
            <Grid2 container spacing={2}>
                <Grid2 xs={3} md={4}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" color="textSecondary">
                            Type:
                        </Typography>
                        <Typography variant="body2" sx={styles.valueStyle}>
                            {document_type || ''}
                        </Typography>
                    </Box>
                </Grid2>
                <Grid2 xs={9} md={4}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" color="textSecondary">
                            Date:
                        </Typography>
                        <Typography variant="body2" sx={styles.valueStyle}>
                            {document_date || ''}
                        </Typography>
                    </Box>
                </Grid2>
                <Grid2 xs={12} md={4}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" color="textSecondary">
                            e-Invoice Code:
                        </Typography>
                        <Typography variant="body2" fontWeight={"bold"} sx={styles.valueStyle}>
                            {invoice_number || ''}
                        </Typography>
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    )
}

const styles = {
    boxContainer: {

    },
    labelStyle: {

    },
    valueStyle: {
        paddingLeft: '1rem',
    }
}

export default InvoiceSection;
